<template>
  <v-row justify="center">
    <v-col sm="10">
      <v-card>
        <v-card-title>
          {{ $t('taskDashboard.table.title') }}
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('taskDashboard.table.search')"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tableData"
          :search="search"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-btn
              block
              class="mt-2 mb-2"
              color="primary"
              elevation="2"
              @click="$router.push({ name: 'TaskDetailCar', params: { id: item.id }})"
            >
              {{ $t('taskDashboard.table.action') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: '',
      headers: [
        { text: 'Typ', value: 'name' },
        { text: 'Vozidlo', value: 'subType' },
        { text: this.$t('taskDashboard.table.header.assigned'), value: 'assigned' },
        { text: 'Status auta', value: 'carStatus' },
        { text: 'Status úlohy', value: 'taskStatus' },
        { text: this.$t('taskDashboard.table.header.action'), value: 'actions', sortable: false }
      ]
    }
  }
}
</script>

<template>
  <v-row
    class="text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('taskDashboard.title') }}</h1>
      <v-spacer />
      <task-table :table-data="tasks" />
    </v-col>
    <v-col
      v-if="getUserData.role === 'ADMIN'"
      class="mb-6"
      cols="12"
    >
      <v-btn
        x-large
        color="primary"
        @click="downloadFile"
      >
        {{ $t('taskDashboard.download') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskTable from './TaskTable'
import downloadCSV from '@/helpers/downloadCSV'

export default {
  name: 'ErrorDashboard',
  components: {
    TaskTable
  },
  data () {
    return {
      tasks: []
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const statusMap = {
          PLANNED_MAINTENANCE: 'Plánovaná oprava',
          RUNNING: 'Funkčný stav, ale potreba servisu',
          POOR_QUALITY: 'Nekvalita',
          LIMITED: 'Obmedzená funkčnosť',
          STOPPED: 'STOP STAV'
        }
        const response = await this.$axios.get('/car-tasks/?select=id,carError(name,carType(*)),user(firstName,lastName),createdAt,startedAt,finishedAt,status')
        const saveData = response.data.map(task => {
          let taskStatus = this.$t('taskDetail.done')
          if (!task.startedAt) {
            taskStatus = this.$t('taskDetail.notStarted')
          } else if (task.startedAt && !task.finishedAt) {
            taskStatus = this.$t('taskDetail.inProgress')
          }
          return {
            id: task.id,
            name: task.carError.carType.name,
            subType: task.carError.carType.subType !== null ? task.carError.carType.subType : task.carError.carType.name,
            assigned: `${task.user.firstName} ${task.user.lastName}`,
            carStatus: statusMap[task.status],
            taskStatus
          }
        })
        this.tasks = saveData
      } catch (err) {
        console.error(err)
      }
    },
    downloadFile () {
      downloadCSV(this.tasks, 'data.csv')
    }
  }
}
</script>
